import React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '@components/layout'
import { Slice } from '@components/slice'
import ArticleListSlice from '@slices/article-list'
import { ArticleListNewSlice } from '@slices/article-list-new/article-list'
// import ArticleListSlice from '@slices/article-list'

type Props = {
  data: any
  pageContext: any
}

const WorksIndex: React.FC<Props> = props => {
  const { meta, header, seo, main, footer } = props.data
  const slices = main?.data?.body
  const works = props?.data?.works
  const news = props?.data?.news

  return (
    <Layout
      seo={seo}
      header={header}
      footer={footer}
      // theme={theme}
      activeMeta={meta}
    >
      <Slice allSlices={slices} />
      {/* <WorksList posts={posts.edges.map(post => post)}> */}
      {/* <ArticleListSlice
        showDate={false}
        posts={works.edges.map(post => post)}
        loadMoreLabel={'See more Works'}
      ></ArticleListSlice> */}

      <ArticleListNewSlice
        showDate={false}
        posts={news?.edges.map(post => post)}
        loadMoreLabel={'See more Workshops'}
        title="Upcoming Workshops"
      ></ArticleListNewSlice>

      <ArticleListNewSlice
        showDate={false}
        posts={works?.edges.map(post => post).slice(0, 3)}
        loadMoreLabel={'See more Workshops'}
        title="Last Workshops"
      ></ArticleListNewSlice>

      {/* </WorksList> */}
    </Layout>
  )
}

export default WorksIndex

export const pageQuery = graphql`
  query WorksIndexPage($locale: String) {
    meta: prismicWorkshopsArchive(lang: { eq: $locale }) {
      url
      lang
      type
      alternate_languages {
        uid
        lang
      }
    }

    seo: prismicWorkshopsArchive(lang: { eq: $locale }) {
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url(imgixParams: { width: 1200, height: 630 })
        }
      }
    }
    main: prismicWorkshopsArchive(lang: { eq: $locale }) {
      data {
        body {
          ... on PrismicWorkshopsArchiveDataBodyFullscreenImage {
            ...fullscreenImageWorkshopsArchive
          }
          ... on PrismicWorkshopsArchiveDataBodyFullscreenVideo {
            ...fullscreenVideoWorkshopsArchive
          }
          ... on PrismicWorkshopsArchiveDataBodyTitle {
            ...titleWorkshopsArchive
          }
          ... on PrismicWorkshopsArchiveDataBodyContent {
            ...contentWorkshopsArchive
          }
        }
      }
    }
    news: allPrismicNewsPost(
      sort: { fields: [data___date], order: DESC }
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          uid
          url
          data {
            date(formatString: "MMMM YYYY")
            feature_image {
              gatsbyImageData
              alt
            }
            post_title {
              text
            }
            superscription {
              richText
            }
          }
        }
      }
    }
    works: allPrismicWorkshopsPost(
      sort: { fields: [data___date], order: DESC }
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          uid
          url
          data {
            date(formatString: "MMMM YYYY")
            feature_image {
              gatsbyImageData
              alt
              fluid {
                aspectRatio
              }
            }
            post_title {
              text
              richText
            }
            superscription {
              text
              richText
            }
          }
        }
      }
    }
    header: prismicMenu(lang: { eq: $locale }) {
      ...header
    }

    footer: prismicFooter(lang: { eq: $locale }) {
      ...footer
    }
  }
`
